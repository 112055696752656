import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import * as Sentry from "@sentry/angular";
import { IJWTPayload } from "src/app/main/model/auth/auth.model";
import { AuthService } from "src/app/main/services/auth/auth.service";
import { JwtService } from "src/app/main/services/auth/jwt.service";
import { UserService } from "src/app/main/services/auth/user.service";
import { SessionService } from "src/app/main/services/session.service";
import { ToastService } from "src/app/main/services/toast.service";
import { logger } from "src/app/main/util/Logger";
import { emailValidator } from "src/app/main/validators/reactiveFormValidators";
import { environment } from "src/environments/environment";

interface ICredentials {
  email: string;
  password: string;
}

const className = "LoginComponent";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html", // Use a relative path here
  styleUrls: ["./login.component.scss"], // Use a relative path here
})
export class LoginComponent implements OnInit {
  public loginForm: FormGroup;

  constructor(
    private session: SessionService,
    private router: Router,
    private authService: AuthService,
    private userService: UserService,
    private jwtService: JwtService,
    private fb: FormBuilder,
    private toastService: ToastService,
  ) {}

  ngOnInit() {
    this.loginForm = this.fb.group({
      email: [environment.defaultAuthEmail || "", [Validators.required, emailValidator]],
      password: [environment.defaultAuthPassword || "", [Validators.required]],
    });
  }

  onSubmit() {
    const signature = className + ".onSubmit: ";
    if (this.loginForm.invalid) {
      return;
    }

    this.session.lockInputRx(this.authService.authenticate(this.loginForm.value)).subscribe({
      next: async (data: IJWTPayload) => {
        if (!this.jwtService.saveJWTData(data) || !this.authService.isAuthenticated()) {
          this.jwtService.removeJWTData();

          throw "Error Saving JWT Payload";
        }

        const userId = this.jwtService.currentJwtPayload$.value?.user.id;
        if (!userId) throw new Error("User ID not found in JWT Payload");

        await this.userService.setUser(userId);

        const credentials = this.loginForm.value as ICredentials;

        if (credentials.email === credentials.password) {
          if (!environment.production) {
            console.warn("User password is equal to user email");
            console.warn("In Production ENV User would be warned and requested to update password");

            logger.silly(signature + `Navigating to Url[${this.userService.defaultLandingPage}]`);
            this.router.navigateByUrl(this.userService.defaultLandingPage);
          } else {
            // this.modalService.confirmRx({
            //   title: "Password Security",
            //   message: "To enhance security, we request that you reset your password using your account settings.",
            //   confirmText: "Reset Password",
            //   declineText: "Ok"
            // }).subscribe((isConfirmed) => {
            //   if (isConfirmed) {
            //     this.router.navigateByUrl('/profile');
            //   } else {
            //     // Allow the user to move onto the dashboard
            //     this.router.navigateByUrl('/dashboard');
            //   }
            // });
          }
        } else {
          // Grant the credentials
          logger.silly(signature + `Navigating to Url[${this.userService.defaultLandingPage}]`);
          this.router.navigateByUrl(this.userService.defaultLandingPage);
        }
      },
      error: (error) => {
        logger.silly(signature + `Error[${error}]`);
        Sentry.captureException(error);
        this.loginForm.reset();
        if (error.statusCode === 403) {
          this.toastService.showError(error.detail.message);
          return;
        }

        throw error;
      },
    });
  }

  //Helper function

  get f() {
    return this.loginForm.controls;
  }
}
