import { Injectable } from "@angular/core";
import jwt_decode from "jwt-decode";
import { CookieService } from "ngx-cookie-service";
import { BehaviorSubject } from "rxjs";
import { environment } from "src/environments/environment";
import { IJWTPayload, IJWTPayloadDecoded } from "../../model/auth/auth.model";
import { logger } from "../../util/Logger";
import { StorageServiceKey } from "../storage.service";

const className = "JwtService";

@Injectable({
  providedIn: "root",
})
export class JwtService {
  // currentJwtPayload should reflect the value currently stored in cookies and be used as a proxy for the current known JWT payload
  public currentJwtPayload$: BehaviorSubject<IJWTPayloadDecoded | null> = new BehaviorSubject(null);

  constructor(public storageService: CookieService) {
    // Check for existing Cookie details
    logger.silly(
      className +
        `.constructor: Checking for existing cookie '${
          StorageServiceKey.jwt
        }': [${this.storageService.check(StorageServiceKey.jwt)}]`,
    );
    logger.silly(
      className +
        `.constructor: Checking for existing cookie '${
          StorageServiceKey.jwt_refresh
        }': [${this.storageService.check(StorageServiceKey.jwt_refresh)}]`,
    );
    logger.silly(
      className +
        `.constructor: Checking for existing cookie '${
          StorageServiceKey.jwt_token_type
        }': [${this.storageService.check(StorageServiceKey.jwt_token_type)}]`,
    );

    const jwt = this.decodeJWT();
    if (jwt) {
      this.currentJwtPayload$.next(jwt);
    }
  }

  public saveJWTData(jwtPayload: IJWTPayload): boolean {
    const signature = className + ".saveJWTData: ";
    /**
     * Verify jwt before saving
     */
    const payload = this.decodeJWT(jwtPayload.accessToken);

    if (!payload) {
      logger.silly(signature + "Jwt was not valid and was rejected");
      return false;
    }

    const payloadVerified = this.verifyJWT(payload);

    if (!payloadVerified) {
      logger.silly(signature + "Jwt did not pass verification and was rejected");
      return false;
    }

    this.storageService.set(
      StorageServiceKey.jwt,
      jwtPayload.accessToken,
      30,
      "/",
      environment.siteDomain,
      true,
      "Strict",
    );
    this.storageService.set(
      StorageServiceKey.jwt_refresh,
      jwtPayload.refreshToken,
      30,
      "/",
      environment.siteDomain,
      true,
      "Strict",
    );
    this.storageService.set(
      StorageServiceKey.jwt_token_type,
      jwtPayload.tokenType,
      30,
      "/",
      environment.siteDomain,
      true,
      "Strict",
    );
    this.currentJwtPayload$.next(payload);

    return true;
  }

  public getJWTString(): string {
    return this.storageService.get(StorageServiceKey.jwt) || "";
  }

  public getJWTRefreshString(): string {
    return this.storageService.get(StorageServiceKey.jwt_refresh) || "";
  }

  public getJWTTypeString(): string {
    return this.storageService.get(StorageServiceKey.jwt_token_type) || "";
  }

  private decodeJWT(token?: string): IJWTPayloadDecoded | null {
    const signature = className + ".decodeJWT: ";
    const jwt = token || this.storageService.get(StorageServiceKey.jwt);
    let payload: IJWTPayloadDecoded;

    if (!jwt) {
      logger.silly(signature + "JWT is null");
      return null;
    }

    try {
      logger.silly(signature + `Decoding JWT[${jwt}]`);
      payload = jwt_decode(jwt);
    } catch (e) {
      logger.silly(signature + "Decoding Error");
      console.log(e);
      return null;
    }

    if (!payload) {
      logger.silly(signature + "Payload is null");
      return null;
    }

    return payload;
  }

  public verifyJWT(token?: string | IJWTPayloadDecoded): boolean {
    const signature = className + ".verifyJWT: ";
    logger.silly(signature + `Verifying using token From[${token ? "args" : "decode"}]`);
    const payload = token
      ? (typeof token === "string" && this.decodeJWT(token)) || (token as IJWTPayloadDecoded)
      : this.decodeJWT();

    if (!payload) {
      logger.silly(signature + "Payload is null");
      return false;
    }

    if (payload.exp < Math.round(new Date().getTime() / 1000)) {
      logger.silly(signature + "Payload is expired");
      return false;
    }

    return true;
  }

  public removeJWTData() {
    this.storageService.delete(StorageServiceKey.jwt);
    this.storageService.delete(StorageServiceKey.jwt_refresh);
    this.storageService.delete(StorageServiceKey.jwt_token_type);
    this.currentJwtPayload$.next(null);
  }
}
