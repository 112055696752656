import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";
import { logger } from "../../util/Logger";
import { ToastService } from "../toast.service";
import { AuthService } from "./auth.service";
import { JwtService } from "./jwt.service";
import { UserService } from "./user.service";

const className = "AuthGuardService";

@Injectable({
  providedIn: "root",
})
export class AuthGuardService implements CanActivate {
  constructor(
    private authService: AuthService,
    private jwtService: JwtService,
    private userService: UserService,
    private toastService: ToastService,
    private router: Router,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const signature = className + ".canActivate: ";
    const fallbackUrlTree = this.router.createUrlTree(["/login"]);

    logger.silly(signature + `Entered for url [${route.url}]`);

    if (this.userService.currentUser) {
      // currentUser data is loaded
      logger.silly(signature + `Found known User[${this.userService.currentUser.id}]`);
      return true;
    } else if (this.authService.isAuthenticated()) {
      // there is a valid JWT but we need to load the currentUser data
      const userId = this.jwtService.currentJwtPayload$.value?.user.id;
      if (!userId) {
        this.toastService.showError("You must be logged in to access this page");
        return fallbackUrlTree;
      }

      return new Promise(async (resolve) => {
        logger.silly(signature + `Loading User[${userId}]`);
        const result = await this.userService.setUser(userId);
        resolve(result || fallbackUrlTree);
      });
    } else {
      logger.silly(signature + `User not logged in for url [${route.url}]`);
      this.toastService.showError("You must be logged in to access this page");
      return fallbackUrlTree;
    }
  }
}
