import { Transform, Type } from "class-transformer";
import { BaseModel } from "../base.model";
import { Question } from "./question.model";

export class QuestionVariant extends BaseModel {
  id: number;
  @Transform(({ value }) => decodeURIComponent(value || ""))
  value: string;
  @Transform(({ value }) => decodeURIComponent(value || ""))
  description: string;
  @Transform(({ value }) => decodeURIComponent(value || ""))
  note: string;
  qId: number;
  variantDeliveryMethodId: number;
  variantLanguageId: number;
  @Type(() => Question)
  question: Question;
}
